@import url('https://fonts.googleapis.com/css?family=Fira%20Code:700|Fira%20Code:400');





:root {
  --menu-figure-size: calc(7.5vw + 7.5vh)
}

:root[data-theme="light"] {
    --text-50: #f2f2f2;
    --text-100: #e6e6e6;
    --text-200: #cccccc;
    --text-300: #b3b3b3;
    --text-400: #999999;
    --text-500: #808080;
    --text-600: #666666;
    --text-700: #4d4d4d;
    --text-800: #333333;
    --text-900: #1a1a1a;
    --text-950: #0d0d0d;
  
    --background-50: #f2f2f2;
    --background-100: #e6e6e6;
    --background-200: #cccccc;
    --background-300: #b3b3b3;
    --background-400: #999999;
    --background-500: #808080;
    --background-600: #666666;
    --background-700: #4d4d4d;
    --background-800: #333333;
    --background-900: #1a1a1a;
    --background-950: #0d0d0d;
  
    --primary-50: #ffefe5;
    --primary-100: #ffdfcc;
    --primary-200: #ffbe99;
    --primary-300: #ff9e66;
    --primary-400: #ff7e33;
    --primary-500: #ff5e00;
    --primary-600: #cc4b00;
    --primary-700: #993800;
    --primary-800: #662500;
    --primary-900: #331300;
    --primary-950: #1a0900;
  
    --secondary-50: #faeaea;
    --secondary-100: #f6d5d5;
    --secondary-200: #ecacac;
    --secondary-300: #e38282;
    --secondary-400: #d95959;
    --secondary-500: #d02f2f;
    --secondary-600: #a62626;
    --secondary-700: #7d1c1c;
    --secondary-800: #531313;
    --secondary-900: #2a0909;
    --secondary-950: #150505;
  
    --accent-50: #fef7e7;
    --accent-100: #fdeece;
    --accent-200: #fadd9e;
    --accent-300: #f8cc6d;
    --accent-400: #f6bb3c;
    --accent-500: #f4aa0b;
    --accent-600: #c38809;
    --accent-700: #926607;
    --accent-800: #614405;
    --accent-900: #312202;
    --accent-950: #181101;
}

:root[data-theme="dark"] {
    --text-50: #0d0d0d;
    --text-100: #1a1a1a;
    --text-200: #333333;
    --text-300: #4d4d4d;
    --text-400: #666666;
    --text-500: #808080;
    --text-600: #999999;
    --text-700: #b3b3b3;
    --text-800: #cccccc;
    --text-900: #e6e6e6;
    --text-950: #f2f2f2;
  
    --background-50: #0d0d0d;
    --background-100: #1a1a1a;
    --background-200: #333333;
    --background-300: #4d4d4d;
    --background-400: #666666;
    --background-500: #808080;
    --background-600: #999999;
    --background-700: #b3b3b3;
    --background-800: #cccccc;
    --background-900: #e6e6e6;
    --background-950: #f2f2f2;
  
    --primary-50: #1a0900;
    --primary-100: #331300;
    --primary-200: #662500;
    --primary-300: #993800;
    --primary-400: #cc4b00;
    --primary-500: #ff5e00;
    --primary-600: #ff7e33;
    --primary-700: #ff9e66;
    --primary-800: #ffbe99;
    --primary-900: #ffdfcc;
    --primary-950: #ffefe5;
  
    --secondary-50: #150505;
    --secondary-100: #2a0909;
    --secondary-200: #531313;
    --secondary-300: #7d1c1c;
    --secondary-400: #a62626;
    --secondary-500: #d02f2f;
    --secondary-600: #d95959;
    --secondary-700: #e38282;
    --secondary-800: #ecacac;
    --secondary-900: #f6d5d5;
    --secondary-950: #faeaea;
  
    --accent-50: #181101;
    --accent-100: #312202;
    --accent-200: #614405;
    --accent-300: #926607;
    --accent-400: #c38809;
    --accent-500: #f4aa0b;
    --accent-600: #f6bb3c;
    --accent-700: #f8cc6d;
    --accent-800: #fadd9e;
    --accent-900: #fdeece;
    --accent-950: #fef7e7;
}

.text-left {
    text-align: left;
    margin: 10px;
    padding: 10px;
}

.container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.box-first {
    background-color: var(--background-100);
    text-align: center;
    box-sizing: border-box;
    margin: 10px;
    width: 90%;
    max-width: max(30vw, 600px);
    padding: 10px;
    border-radius: 7.5px;
}

.date-intervall {
    color: var(--text-500);
    margin-top: 2.5px;
    margin-bottom: 2.5px;
    font-size: small;
}

.overhead {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    align-items: top;
    height: 50px;
}

.drop-down {
    background-color: color-mix(in srgb, var(--background-100), var(--background-200));
    border-radius: 7.5px;
    text-align: left;
    margin: 10px;
}


a {
    text-decoration: none;
    color: var(--primary-500);
}

a:hover {
    color: var(--primary-700)
}

.semester > h5 {
    margin-top: 0px;
    margin-bottom: 2.5px;
}

.study-header {
    transition-duration: 0.1s;
    border-radius: 7.5px;
    padding: 10px;
}

.study-header:hover {
    box-shadow: 2px 2px 5px 0 #0000003d;
    background-color: color-mix(in srgb, var(--background-100), var(--background-200) 60%);
    cursor: pointer;
}

.study-header:active {
    background-color: var(--background-200);
}

.links {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

.semester {
    background-color: color-mix(in srgb, var(--background-100), var(--background-200));
    border-radius: 7.5px;
    padding: 10px;
    margin: 10px;
    border: solid 1.5px;
    border-color: var(--text-500);
}

.semester > h5 {
    font-size: 0.95rem;
}

ul {
    margin: 0;
}

hr {
    margin: 10px;
}