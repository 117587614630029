@import url('https://fonts.googleapis.com/css?family=Fira%20Code:700|Fira%20Code:400');





:root {
  --menu-figure-size: calc(7.5vw + 7.5vh)
}

:root[data-theme="light"] {
    --text-50: #f2f2f2;
    --text-100: #e6e6e6;
    --text-200: #cccccc;
    --text-300: #b3b3b3;
    --text-400: #999999;
    --text-500: #808080;
    --text-600: #666666;
    --text-700: #4d4d4d;
    --text-800: #333333;
    --text-900: #1a1a1a;
    --text-950: #0d0d0d;
  
    --background-50: #f2f2f2;
    --background-100: #e6e6e6;
    --background-200: #cccccc;
    --background-300: #b3b3b3;
    --background-400: #999999;
    --background-500: #808080;
    --background-600: #666666;
    --background-700: #4d4d4d;
    --background-800: #333333;
    --background-900: #1a1a1a;
    --background-950: #0d0d0d;
  
    --primary-50: #ffefe5;
    --primary-100: #ffdfcc;
    --primary-200: #ffbe99;
    --primary-300: #ff9e66;
    --primary-400: #ff7e33;
    --primary-500: #ff5e00;
    --primary-600: #cc4b00;
    --primary-700: #993800;
    --primary-800: #662500;
    --primary-900: #331300;
    --primary-950: #1a0900;
  
    --secondary-50: #faeaea;
    --secondary-100: #f6d5d5;
    --secondary-200: #ecacac;
    --secondary-300: #e38282;
    --secondary-400: #d95959;
    --secondary-500: #d02f2f;
    --secondary-600: #a62626;
    --secondary-700: #7d1c1c;
    --secondary-800: #531313;
    --secondary-900: #2a0909;
    --secondary-950: #150505;
  
    --accent-50: #fef7e7;
    --accent-100: #fdeece;
    --accent-200: #fadd9e;
    --accent-300: #f8cc6d;
    --accent-400: #f6bb3c;
    --accent-500: #f4aa0b;
    --accent-600: #c38809;
    --accent-700: #926607;
    --accent-800: #614405;
    --accent-900: #312202;
    --accent-950: #181101; 
}

:root[data-theme="dark"] {
    --text-50: #0d0d0d;
    --text-100: #1a1a1a;
    --text-200: #333333;
    --text-300: #4d4d4d;
    --text-400: #666666;
    --text-500: #808080;
    --text-600: #999999;
    --text-700: #b3b3b3;
    --text-800: #cccccc;
    --text-900: #e6e6e6;
    --text-950: #f2f2f2;
  
    --background-50: #0d0d0d;
    --background-100: #1a1a1a;
    --background-200: #333333;
    --background-300: #4d4d4d;
    --background-400: #666666;
    --background-500: #808080;
    --background-600: #999999;
    --background-700: #b3b3b3;
    --background-800: #cccccc;
    --background-900: #e6e6e6;
    --background-950: #f2f2f2;
  
    --primary-50: #1a0900;
    --primary-100: #331300;
    --primary-200: #662500;
    --primary-300: #993800;
    --primary-400: #cc4b00;
    --primary-500: #ff5e00;
    --primary-600: #ff7e33;
    --primary-700: #ff9e66;
    --primary-800: #ffbe99;
    --primary-900: #ffdfcc;
    --primary-950: #ffefe5;
  
    --secondary-50: #150505;
    --secondary-100: #2a0909;
    --secondary-200: #531313;
    --secondary-300: #7d1c1c;
    --secondary-400: #a62626;
    --secondary-500: #d02f2f;
    --secondary-600: #d95959;
    --secondary-700: #e38282;
    --secondary-800: #ecacac;
    --secondary-900: #f6d5d5;
    --secondary-950: #faeaea;
  
    --accent-50: #181101;
    --accent-100: #312202;
    --accent-200: #614405;
    --accent-300: #926607;
    --accent-400: #c38809;
    --accent-500: #f4aa0b;
    --accent-600: #f6bb3c;
    --accent-700: #f8cc6d;
    --accent-800: #fadd9e;
    --accent-900: #fdeece;
    --accent-950: #fef7e7;
  
  }
  


body {
    background-color: var(--background-50);
    color: var(--text-950);
}

h1, h2, h3, h4, h5 {
  font-family: 'Fira Code', monospace;
  font-weight: 700;
}

html {font-size: 100%;} 

h1 {font-size: 3rem; }

h2 {font-size: 2.5rem; }

h3 {font-size: 2rem; }

h4 {font-size: 1.5rem; }

h5 {font-size: 1rem; }

small {font-size: 0.5rem;  }

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-50);
  z-index: -1;
  pointer-events: none;
  overflow: hidden;
}

.sorter {
  width: 100%;
  height: 100%;

  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  
  color: var(--primary-500);
  font-size: calc(1.5vh + 1.5vw);
}

.wrapper {
  width: 80vw;
  height: 80vh;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;

  padding: 10px;
  border: solid 6.5px var(--primary-500);
  border-radius: 15px;
}

.ribbon_topleft {
  position: fixed;
  left: 0;
  top: 0;
  width: var(--menu-figure-size);
  height: var(--menu-figure-size);
  transform: translate(-50%, -50%) rotate(20deg);
  z-index: 2;
}

.ribbon_topright {
  position: fixed;
  left: 100%;
  top: 0;
  width: var(--menu-figure-size);
  height: var(--menu-figure-size);
  transform: translate(-50%, -50%) rotate(-20deg);
  z-index: 2;
}

.ribbon_bottomleft {
  position: fixed;
  left: 0;
  top: 100%;
  width: var(--menu-figure-size);
  height: var(--menu-figure-size);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.ribbon_bottomright {
  position: fixed;
  left: 100%;
  top: 100%;
  width: var(--menu-figure-size);
  height: var(--menu-figure-size);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.duck_topleft {
  position: fixed;
  left: 0;
  top: 0;
  width: var(--menu-figure-size)/1.5;
  height: var(--menu-figure-size)/1.5;
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 2;
}

.duck_topright {
  position: fixed;
  left: 100%;
  top: 0;
  width: var(--menu-figure-size)/1.5;
  height: var(--menu-figure-size)/1.5;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: 2;
}

.duck_bottomleft {
  position: fixed;
  left: 0;
  top: 100%;
  width: var(--menu-figure-size)/1.5;
  height: var(--menu-figure-size)/1.5;
  transform: translate(-50%, -50%) rotate(-135deg);
  z-index: 2;
}

.duck_bottomright {
  position: fixed;
  left: 100%;
  top: 100%;
  width: var(--menu-figure-size)/1.5;
  height: var(--menu-figure-size)/1.5;
  transform: translate(-50%, -50%) rotate(135deg);
  z-index: 2;
}

.menu {
  background: color-mix(in srgb, var(--background-50), transparent 5%);
  color: var(--text-950);
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: fit-content;
  height: fit-content;
  padding: 1vh;
  text-align: center;
  font-size: calc(1vh + 1vw);
  border-radius: 30px;
}

.theme_button {
  background-color: var(--background-100);
  color: var(--text-950);
  border-radius: 7.5px;
  font-size: 1rem;
  padding: 15px;
  margin: 10px;
  border: none;
  transition-duration: 0.1s;
  white-space: nowrap;
}

.theme_button:hover { 
  box-shadow: 2px 2px 5px 0 #0000003d;
  background-color: color-mix(in srgb, var(--background-100), var(--background-200));
  cursor: pointer;
}

.theme_button:active { 
  margin-top: 15px;
}

.menu_button {
  background-color: var(--background-100);
  color: var(--text-950);
  border-radius: 10px;
  font-size: 1rem;
  padding: 15px;
  margin: 10px;
  border: none;
  transition-duration: 0.1s;
  white-space: nowrap;
}

.menu_button:hover { 
  box-shadow: 2px 2px 5px 0 #0000003d;
  background-color: color-mix(in srgb, var(--background-100), var(--background-200));
  cursor: pointer;
}

.menu_button:active { 
  margin-top: 15px;
}

.link {
  color: var(--text-950);
  text-decoration: none;
}